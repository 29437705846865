<template>
  <!-- Hire the most <br class='hidden xl:inline-block' />
            rigorously vetted,  <br class='hidden xl:inline-block' />
            highly skilled talents from Africa -->
  <Hero
    heroTitle="We help you find and <br class='hidden xl:inline-block' /> hire amazing employees."
    heroDesc="We're creating a smarter way to hire by blending the latest technology and a <br class='hidden xl:inline-block' /> personal touch to help companies hire and manage the right talents."
    heroBtnText="Find your next Hire"
    heroBtnLink="https://employer.thecareerbuddy.com/login"
    heroBtnTwoText="Or Get in touch"
    heroBtnTwoLink="/contact"
  />

  <Customers heroImg="bigHero.svg" imageContainerClass="xl:-top20" class="" />

  <div class="flex justify-center bg-white px-[24px] md:px-[80px] py-8 md:py-[48px]">
    <div class="w-full md:w[72vw]">
      <h2 class="text-center mb-3 text-[32px] md:text-[44px] text-brand-black leading-[110%] font-normal helvetica tracking-[-4%]">
        Why startup hiring teams love CareerBuddy
      </h2>
      <div class="md:gap-x-20 flex justify-center mb-[34px] md:flex-row gap-y-6 flex-col items-center">
        <div>
          <p class="text-[100px] md:text-[86px] text-brand-black leading-[110%] font-normal">
            <count-up :startVal="0" :endVal="90" :duration="10" :options="options" :delay="50" loop></count-up>
          </p>
          <small class="text-[15px] text-center leading-6 hel">Candidate response rate</small>
        </div>
        <div>
          <p class="text-[100px] md:text-[86px] text-brand-black leading-[110%] font-normal">
            <count-up :startVal="0" :endVal="60" :duration="10" :options="options" :delay="50" loop></count-up>
          </p>
          <small class="text-[15px] text-center leading-6">Interview acceptance rate</small>
        </div>
        <div>
          <p class="text-[100px] md:text-[86px] text-brand-black leading-[110%] font-normal text-center">
            <count-up :startVal="0" :endVal="85" :duration="10" :options="options" :delay="50" loop></count-up>
          </p>
          <small class="md:text-[15px] text-center leading-6">Candidates hired stay longer than 2 years</small>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-white md:px-[80px] py-8 md:py-[60px]">
    <div class="flex items-center flex-col md:flex-row justify-center gap-4">
      <div class="bg-[#FAFAFA] md:px-[60px] px-[24px] md:py-20 py-6 md:w-[632px] md:h-[771px]">
        <div class="">
          <p>Expanding your team?</p>
          <p>Why hiring teams love us!</p>
        </div>
        <div class="md:my-[60px] my-8 space-y-8">
          <div class="flex md:items-center">
            <img v-lazy="require(`@/assets/icons/list.svg`)" alt="" />
            <p class="pl-4 text-[#525252] md:text-lg text-sm">Connect faster with highly qualified professionals who succeeded in previous roles.</p>
          </div>
          <div class="flex md:items-center">
            <img v-lazy="require(`@/assets/icons/list.svg`)" alt="" />
            <p class="pl-4 text-[#525252] md:text-lg text-sm">
              Access to quality profiles with a unique blend of skills, experience, and dedication.
            </p>
          </div>
          <div class="flex md:items-center">
            <img v-lazy="require(`@/assets/icons/list.svg`)" alt="" />
            <p class="pl-4 text-[#525252] md:text-lg text-sm">Receive top-tier candidate profiles under four days.</p>
          </div>
          <div class="flex md:items-center">
            <img v-lazy="require(`@/assets/icons/list.svg`)" alt="" />
            <p class="pl-4 text-[#525252] md:text-lg text-sm">Advice on roles, profiles and scaling teams.</p>
          </div>
          <div class="flex md:items-center">
            <img v-lazy="require(`@/assets/icons/list.svg`)" alt="" />
            <p class="pl-4 text-[#525252] md:text-lg text-sm">Consistent support and advice to suit your hiring needs</p>
          </div>
        </div>
        <div class="gap-4 flex-col flex md:flex-row text-center">
          <a href="/employer" class="px-6 py-3.5 bg-transparent inline-block rounded-xl text-brand-black font-bold text-base border border-[#1A1A1A]"
            >Learn more</a
          >
          <a href="https://employer.thecareerbuddy.com/login" class="px-6 py-3.5 bg-brand-black inline-block rounded-xl text-white font-bold text-base">Sign Up</a>
        </div>
      </div>
      <div class="bg-[#FEFAF1] md:px-[60px] px-[24px] md:py-20 py-6 md:w-[632px] md:h-[771px]">
        <div class="">
          <p>Looking for a job?</p>
          <p>Find out why job seekers love us!</p>
        </div>
        <div class="md:my-[60px] my-8 space-y-8">
          <div class="flex md:items-center">
            <img src="@/assets/icons/list.svg" alt="" />
            <p class="pl-4 text-[#525252] md:text-lg text-sm">Rare career opportunities with exceptional companies</p>
          </div>
          <div class="flex md:items-center">
            <img v-lazy="require(`@/assets/icons/list.svg`)" alt="" />
            <p class="pl-4 text-[#525252] md:text-lg text-sm">
              Absolute confidentiality. We don’t leave you hanging. We update you on the hiring process.
            </p>
          </div>
          <div class="flex md:items-center">
            <img v-lazy="require(`@/assets/icons/list.svg`)" alt="" />
            <p class="pl-4 text-[#525252] md:text-lg text-sm">Connect directly with Founders from seed to global tech giants</p>
          </div>
          <div class="flex md:items-center">
            <img v-lazy="require(`@/assets/icons/list.svg`)" alt="" />
            <p class="pl-4 text-[#525252] md:text-lg text-sm">Work with companies who offer attractive compensation</p>
          </div>
          <div class="flex md:items-center">
            <img v-lazy="require(`@/assets/icons/list.svg`)" alt="" />
            <p class="pl-4 text-[#525252] md:text-lg text-sm">Insightful career resources to take you from average to elite</p>
          </div>
        </div>
        <div class="gap-4 flex-col flex md:flex-row text-center">
          <a href="/candidate" class="px-6 py-3.5 bg-transparent inline-block rounded-xl text-brand-black font-bold text-base border border-[#1A1A1A]"
            >Learn more</a
          >
          <a href="https://candidate.thecareerbuddy.com/login" class="px-6 py-3.5 bg-brand-black inline-block rounded-xl text-white font-bold text-base">Sign Up</a>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-white md:px-[80px] p-6 md:py-[60px]">
    <small class="text-lg text-[#485467] mb-4">Companies and Hiring Teams</small>
    <p class="mb-16 md:text-[40px] text-[32px] text-[#161C2C] lg:w-[66%]">
      Our Platform lets you connect to candidates who  are qualified by the CareerBuddy talent team and ready to interview so you’ll spend more time
      meeting candidates, not trying to find them.
    </p>
    <Hiring :details="employerDetails" />
  </div>

  <Steps />

  <div class="bg-white md:px-[80px] p-6 md:py-[60px]">
    <small class="text-lg text-[#485467]">For Candidates</small>
    <p class="mb-16 md:text-[40px] text-[32px] text-[#161C2C] lg:w-[66%]">
      Companies of various sizes and industries turn to CareerBuddy to fill their open roles. We match your preferences and qualifications with
      employers ready to fill open positions.
    </p>

    <Hiring :details="talentDetails" reverse />
  </div>

  <div class="md:px-20 lg:py-[100px] p-6 md:flex justify-evenly">
    <testimonial />
  </div>

  <div class="md:px-20 md:py-[60px] px-6 py-[42px] flex justify-evenly flex-col">
    <p class="text-[#161C2C] font-medium text-[44px] mx-auto mb-16">Frequently asked questions</p>
    <template v-for="(faq, index) in faqs" :key="index">
      <Accordion :title="faq.title" :id="faq.id" :active="faq.active">
        <ul v-if="faq.list === true" class="list-disc list-inside space-y-2 md:ml-[51px]">
          <li v-for="item in faq.text" :key="item.id" class="text-[#AEB7C6] text-base md:text-2xl font-normal">{{ item }}</li>
        </ul>
        <p v-else class="text-[#AEB7C6] text-base md:text-2xl font-normal md:ml-[51px]">{{ faq.text }}</p>
      </Accordion>
    </template>
  </div>
  <div
    class="flex justify-center bg-cover bg-no-repeat md:px-20 px-6 py-[60px]"
    :style="`background-image: url(${require('@/assets/images/globalBg.svg')})`"
  >
    <div class="w-full lg:w-[41vw]">
      <img src="@/assets/images/global.svg" alt="" class="mx-auto w-1/2 md" />
      <p class="md:text-[44px] text-[32px] text-brand-black py-6 text-center">Your #1 Global Partner for hiring</p>
      <p class="text-[#544A2E] text-2xl pb-6 text-center">
        At CareerBuddy, we're not just a recruitment agency, we're your best choice for discovering top talent worldwide. Our track record of linking
        global companies with premium candidates has made us a top choice for hiring success
      </p>
      <div class="flex flex-col md:flex-row md:items-center gap-4 md:justify-center">
        <a href="/employer" class="px-6 py-3.5 bg-brand-black inline-block rounded-xl text-white text-center">Start Hiring Now</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import Hero from '@/components/partials/Hero.vue';
import Customers from '@/components/partials/Customers.vue';
import Testimonial from '@/components/partials/Testimonial.vue';
import Hiring from '@/components/partials/Hiring.vue';
import Steps from '@/components/partials/Steps.vue';
import Accordion from '@/components/partials/Accordion.vue';
import { useMeta } from '@/utils/meta';
import { onMounted, ref } from 'vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CountUp from 'vue-countup-v3';

const employerDetails = ref([
  {
    id: 1,
    img: 'hiringTeam.svg',
    text: 'Job Portal',
    link: 'https://employer.thecareerbuddy.com/login',
    description: 'Post your open jobs and let candidates who are actively looking apply directly',
    btnText: 'Sign up as an Employer',
  },
  {
    id: 2,
    img: 'pool.svg',
    text: 'Talent Pool',
    link: 'https://employer.thecareerbuddy.com/login',
    description:
      'Cut through the noise to find the best talent as you build your candidate pipeline with ease and find pre-vetted tech candidates to quickly send interview requests.',
    btnText: 'Sign up as an Employer',
  },
  {
    id: 3,
    img: 'companyJob.svg',
    text: 'Company Job Page',
    link: 'https://employer.thecareerbuddy.com/login',
    description:
      'Set up your company profile page and add as much information you can about your company. Let candidates know about your work culture before they apply.',
    btnText: 'Sign up as an Employer',
  },
  {
    img: 'messaging.svg',
    text: 'Messaging',
    link: 'https://employer.thecareerbuddy.com/login',
    description: 'Message candidates as much as you need. Reach out and pitch your opportunity to candidates or respond to applications.',
    btnText: 'Sign up as an Employer',
  },
]);

const talentDetails = ref([
  {
    img: 'hiringTeam.svg',
    text: 'Job Discovery',
    link: 'https://candidate.thecareerbuddy.com/login',
    description:
      'Whether you’re remote, hybrid, or fully in-office, CareerBuddy will connect you directly with hiring managers at high growth companies.',
    id: 1,
    btnText: 'Sign up as a talent',
  },
  {
    img: 'pool.svg',
    text: 'Refer and earn',
    link: '/talents',
    description: 'Know a friend or colleague who’s actively looking and would be a great fit for one of our open roles? Refer and earn',
    id: 2,
    btnText: 'Start Referring',
  },
  {
    img: 'companyJob.svg',
    text: 'CV Writing',
    link: '/cv',
    description: 'Put your best foot forward. Let our team upgrade your CV so you can land your dream job faster',
    id: 3,
    btnText: 'Upgrade my CV',
  },
]);

const currentReview = ref(1);

const faqs = [
  {
    title: 'What is your recruitment process like?',
    text: [
      'We get on a kickoff call with you understand what you require',
      'Internally look through our database or on request, we outsource',
      'After sourcing we prescreen them, and see if they fit into your culture',
      'We support you regularly with interviews',
      'We revert back to candidates to let them know where they stand',
    ],
    active: false,
    id: '01',
    list: true,
  },
  {
    title: 'What sets your agency apart from others?',
    text: 'We are not just looking for skillful people but people who will fit into your organization, possess soft skills, and  understand what it takes to build an organization. You get candidates who tie your organizational goals to their personal and career goals.',
    active: false,
    id: '02',
  },
  {
    title: 'As a job seeker, is it free to create a profile?',
    text: "Yes, it's totally free to create your profile on our platform! That's the first step to discovering tailored opportunities just for you.",
    active: false,
    id: '03',
  },
  {
    title: 'Do you offer any guarantees?',
    text: [
      'You get a 3-month no charge guarantee during the probation period.',
      "If candidates isn't satisfactory, we find you a new replacement after 3 month period",
    ],
    active: false,
    id: '04',
    list: true,
  },
  {
    title: 'Can I receive email notifications for new job openings?',
    text: "Absolutely! When you sign up for our email newsletters, you'll get customized updates about new job openings that match your career and expertise. We are always on the lookout for the best opportunities just for you.</p>",
    active: false,
    id: '05',
  },
];
useMeta();
onMounted(() => {
  gsap.to('.events', { scrollTrigger: '.events', x: 100, duration: 3 });
  gsap.registerPlugin(ScrollTrigger);
});

const options = ref({
  useEasing: true,
  useGrouping: true,
  suffix: '%',
});
</script>

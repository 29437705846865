<template>
  <!-- wfull lg:px[24px] md:w-[78vw] -->
  <main class="md:w-[78vw] w-full">
    <!-- lg:py[5rem] -->
    <!-- <div class=""> -->
    <p class="md:text-center text-[32px] md:text-[44px] text-brand-black mf:leading-[48px] md:font-semibold">Hear what others are saying</p>
    <div class="bg-[#F0F0F0] rounded-[28px] flex items-center justify-center mx-auto md:w-[28%] gap-x-4 md:gap-x-8 md:my-12 my-8">
      <div
        @click="current = 1"
        class="py-3.5 px-5"
        :class="current === 1 ? 'bg-white rounded-[28px] text-base font-bold py-[13px] px-5' : 'text-[#8F8F8F] text-base'"
      >
        Client Reviews
      </div>
      <div
        @click="current = 2"
        class="py-3.5 px-5"
        :class="current === 2 ? 'bg-white rounded-[28px] text-base font-bold py-[13px] px-5' : 'text-[#8F8F8F] text-base'"
      >
        Career Series Reviews
      </div>
    </div>
    <div v-if="current === 1" class="grid md:grid-cols-2 md:gap-y-12 gap-y-8 md:gap-x-8 pb-5">
      <div class="bg-[#FAFAFA] rounded-2xl border border-[#F0F0F0] p-6 md:w-[624px]">
        <div class="flex md:items-center md:justify-between flex-col md:flex-row mb-9">
          <div>
            <p class="font-bold text-base pb-0">Richmond Bassey</p>
            <small class="text-xs text-[#666666]">Co-founder/ CEO Bamboo invest</small>
          </div>
          <img src="@/assets/images/bamboo.svg" class="order-first md:order-none md:mb-0 h-[80px] w-[80px]" alt="" />
        </div>

        <p class="text-brand-black font-normal md:text-xl text-base">
          I think CareerBuddy is the only recruitment company I've worked with that understands startup hiring and I have worked with many. You take
          action fast, understand the uncertainty, need for nimbleness and working in iterations throughout the whole process. You're mentally present
          and almost always help me clarify my implicit needs I haven't even identified. <br />
          <br />
          The best part has always been the quality of people on the other side of the process.
        </p>
      </div>

      <div class="bg-[#FAFAFA] rounded-2xl border border-[#F0F0F0] p-6 md:w-[624px]">
        <div class="flex md:items-center md:justify-between flex-col md:flex-row mb-9">
          <div>
            <p class="font-bold text-base pb-0">Anu Adasolom</p>
            <small class="text-xs text-[#666666]">Chief Executive Officer, Sabi</small>
          </div>
          <img src="@/assets/images/sabi.svg" class="order-first md:order-none md:mb-0 h-[80px] w-[80px]" alt="" />
        </div>

        <p class="text-brand-black font-normal md:text-xl text-base">
          CareerBuddy is the best kept secret in the world of startup recruiting without a doubt. We can't recommend them enough, we have tried a lot
          of recruiting partners even “high profile” ones but with little or no success. CareerBuddy makes recruiting look easy. They understand our
          business like no other organization we work with and they continue to help us with some of our most critical roles.
        </p>
      </div>

      <div class="bg-[#FAFAFA] rounded-2xl border border-[#F0F0F0] p-6 md:w-[624px]">
        <div class="flex md:items-center md:justify-between flex-col md:flex-row mb-9">
          <div>
            <p class="font-bold text-base pb-0">Segun Makinde</p>
            <small class="text-xs text-[#666666]">Chief Operating Officer (Casava)</small>
          </div>
          <img src="@/assets/images/casava.svg" class="order-first md:order-none md:mb-0 h-[80px] w-[80px]" alt="" />
        </div>

        <p class="text-brand-black font-normal md:text-xl text-base">
          CareerBuddy gets it. Hiring isn't a walk in the park but they make the recruitment process a lot less daunting. From how quickly they are
          able to source for candidate to ensuring the candidates are of high quality and a great fit for our company. They are great at what they do.
        </p>
      </div>

      <div class="bg-[#FAFAFA] rounded-2xl border border-[#F0F0F0] p-6 md:w-[624px]">
        <div class="flex md:items-center md:justify-between flex-col md:flex-row mb-9">
          <div>
            <p class="font-bold text-base pb-0">Faisal Bahir</p>
            <small class="text-xs text-[#666666]">People Manager (Crowdforce)</small>
          </div>
          <img src="@/assets/images/crowd.svg" class="order-first md:order-none md:mb-0 h-[80px] w-[80px]" alt="" />
        </div>

        <p class="text-brand-black font-normal md:text-xl text-base">
          CareerBuddy delivers 100% all of the time with quality pool of candidates and great turn around time making my job a lot easier.
        </p>
      </div>
    </div>
    <div v-if="current === 2" class="grid md:grid-cols-2 gap-4 pb-5">
      <div class="space-y-4">
        <div class="bg-[#FAFAFA] rounded-2xl border border-[#F0F0F0] p-6 md:w-[614px] md:h-[468px]">
          <p class="text-brand-black font-normal md:text-xl text-base">
            My experience with CareerBuddy was great. From the time they first reached out on LinkedIn, through the conversations with the hiring
            company and up to the time I received the offer. They were always checking in to know how it was going and helping to follow up with the
            Hiring company. They were also always available to answer any questions I had and pass it on where the didn't have the answers. I totally
            enjoyed working with them…it was easy and seamless. <br />
            <br />
            If you need the right candidate, CareerBuddy is definitely your “go-to” plug. Thanks guys you're amazing. <br/>
            <span class="font-bold">Temilolu (Oval finance).</span>
          </p>
        </div>

        <div class="bg-[#FAFAFA] rounded-2xl border border-[#F0F0F0] p-6 md:w-[614px]">
          <p class="text-brand-black font-normal md:text-xl text-base">
            Still buzzing from yesterday's amazing news! I can't thank you and the team enough for your incredible support throughout the process.
            <br />
            <br />
            Seriously, this has been the smoothest, most transparent recruitment experience I've had in ages. Your guidance and efficiency made
            everything so much easier. You guys are rockstars! <br />
            <br />
            Thank you again from the bottom of my heart. You all went above and beyond, and I truly appreciate it.<br/>
            <span class="font-bold">Jeremiah (Remote Pass)</span>
          </p>
        </div>
      </div>

      <div class="space-y-4">
        <div class="bg-[#FAFAFA] rounded-2xl border border-[#F0F0F0] p-6 md:w-[614px]">
          <p class="text-brand-black font-normal md:text-xl text-base">
            Thanks again for all your support throughout our recruitment process. Truly CareerBuddy is an integral part of my amazing role in
            RemotePass! <br /><br />
            Thanks and regards<br/> <span class="font-bold">Riza Tavesora</span>
          </p>
        </div>

        <div class="bg-[#FAFAFA] rounded-2xl border border-[#F0F0F0] p-6 md:w-[614px]">
          <p class="text-brand-black font-normal md:text-xl text-base">
            I'm so excited to start this new chapter of my career and I am eager to contribute to the success of my team as HR manager. Thank you so
            much for providing such great assistance and guidance during the recruitment process. I really appreciate the experience. <br /><br />
            Best regards, <br/><span class="font-bold">Chaimae, Autochek.</span>
          </p>
        </div>

        <div class="bg-[#FAFAFA] rounded-2xl border border-[#F0F0F0] p-6 md:w-[614px]">
          <div class="flex md:items-center md:justify-between flex-col md:flex-row mb-9">
            <p class="text-brand-black font-normal md:text-xl text-base">
              I appreciate the encouragement and reassurance as I am embark on this amazing journey at Shutlers. It is comforting to know that I have
              such a supporting team by my side. I'll definitely reach out if I need any guidance or clarity along the way. Looking forward to the
              growth ahead and making meaningful contributions to the team. <br /><br />Best regards,<br/>
              <span class="font-bold">Temitayo (Shuttlers)</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </main>
</template>

<script setup>
import { ref } from 'vue';

const current = ref(1);
</script>

<script setup>
import { ref, onMounted } from 'vue';

const accordionOpen = ref(false);

const props = defineProps({
  title: { type: String },
  id: { type: String },
  active: { type: Boolean },
  // list: { type: Boolean },
});

onMounted(() => {
  accordionOpen.value = props.active;
});
</script>

<template>
  <div class="">
    <div>
      <button
        :id="`accordion-title-${id}`"
        class="flex items-center justify-between w-full text-left font-normal py-2"
        @click.prevent="accordionOpen = !accordionOpen"
        :aria-expanded="accordionOpen"
        :aria-controls="`accordion-text-${id}`"
      >
        <p class="text-[#161C2C] helvetica-neue text-2xl md:ml-[51px] mr-6 md:mr-0">
          <span class="md:mr-[51px] mr-2">{{ id }}</span> <span class="text-center">{{ title }}</span>
        </p>
        <svg class="fill-[#AEB7C6] shrink-0" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
          <path
            d="M23.245 4l-11.245 14.374-11.219-14.374-.781.619 12 15.381 12-15.391-.755-.609z"
            class="transform origin-center transition duration-200 ease-out"
            :class="{ '!rotate-180': accordionOpen }"
          />
        </svg>
      </button>
    </div>
    <div
      :id="`accordion-text-${id}`"
      role="region"
      :aria-labelledby="`accordion-title-${id}`"
      class="grid overflow-hidden transition-all duration-300 ease-in-out"
      :class="accordionOpen ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'"
    >
      <div class="overflow-hidden">
        <!-- <p class="pb-3"> -->
        <slot />
        <!-- </p> -->
      </div>
    </div>
    <hr class="border border-[#F3F4F6] md:my-10 my-4" />
  </div>
</template>

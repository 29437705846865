<template>
  <transition appear @before-enter="beforeEnter" @enter="enter">
    <div class="flex justify-center bg-light-yellow px-[24px] pt-[60px] md:px-20">
      <div class="w-full md:w-[76vw]">
        <div class="gap-x-8">
          <div class="w-full md:w[58vw] pb-8 text-center mdtext-left">
            <h1 class="text-brand-black text-[32px] md:text-[60px] font-normal leading-[100%] helvetica" v-html="heroTitle"></h1>
            <p class="mt-4 mb-6 text-[16px] md:text-[20px] leading-[130%] text-[#8F8F8F] font-normal" v-html="heroDesc"></p>
            <div class="gap-4 flex-col flex md:block">
              <a :href="heroBtnLink" class="px-8 py-4 bg-brand-black inline-block rounded-2xl text-white" v-if="heroBtnText">{{ heroBtnText }}</a>
              <a :href="heroBtnTwoLink" class="px-8 py-4 bg-transparent inline-block rounded-2xl text-brand-black font-bold" v-if="heroBtnTwoText">{{ heroBtnTwoText }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import { onMounted } from 'vue';
const props = defineProps({
  heroImg: { type: String },
  heroTitle: { type: String },
  heroDesc: { type: String },
  heroBtnText: { type: String },
  heroBtnLink: { type: String, default: '/contact' },
  heroBtnTwoText: { type: String },
  heroBtnTwoLink: { type: String, default: '/contact' },
  imageContainerClass: String,
  imageClass: { type: String },
});


</script>
<script>
export default {
  data() {
    return {
      showNav: false,
    };
  },
//   methods: {
//     beforeEnter(el) {
//       el.style.opacity = '0';
//       el.style.transform = 'translateY(-100px)';
//     },
//     // where the animation will end up
//     enter(el) {
//       gsap.to(el, {
//         duration: 2.5,
//         // delay: 1,
//         y: 0,
//         x: 0,
//         opacity: 1,
//         ease: "elastic.inOut(1,0.3)",
//       });
//     },
//   },
};

</script>

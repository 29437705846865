<template>
  <main>
    <div class="md:flex hidden itemscenter gap-x-16" :class="reverse ? 'flex-row-reverse' : ''">
      <div class="" >
        <div class="md:w-[495px]" v-for="(item, index) in details" :key="index">
          <p class="text-[26px] cursor-pointer" :class="current === item.id ? 'text-brand-black' : 'text-[#ADADAD]'" @click="current = item.id">
            {{ item.text }}
          </p>
          <hr class="my-4" />
        </div>
        <div class="md:w-[495px]" v-for="(item, index) in details" :key="index">
          <div v-if="current === item.id">
            <p class="text-[#8F8F8F] text-base font-normal mb-6">{{ item.description }}</p>
            <a :href="item.link" class="px-6 py-3.5 text-white inline-block rounded-xl border bg-brand-black">{{ item.btnText }}</a>
          </div>
        </div>
      </div>
      <div class="" v-for="(item, index) in details" :key="index">
        <img v-if="current === item.id" v-lazy="require(`@/assets/images/${item.img}`)" alt="" />
      </div>
    </div>
    <div v-for="(item, index) in details" :key="index" class="pb-16 md:hidden">
      <p class="text-2xl text-brand-black">
        {{ item.text }}
      </p>
      <img v-lazy="require(`@/assets/images/${item.img}`)" alt="image" class="my-4" />
      <div class="flex flex-col">
        <p class="text-[#8F8F8F] text-base font-normal mb-6">{{ item.description }}</p>
        <a :href="item.link" class="px-6 py-3.5 text-white inline-block rounded-xl border bg-brand-black text-center">{{ item.btnText }}</a>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  details: { type: Array },
  reverse: { type: Boolean },
});
const current = ref(1);
</script>
